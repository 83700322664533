import styled from 'styled-components'
import Carousel from 'react-bootstrap/Carousel'
const StyledCarousel = styled(Carousel)`
    display: flex;
    max-width: ${props => props.home ? '80%' : '70%'};
    justify-content: center;
    margin: auto;
    flex-wrap: wrap;
    .carousel-control-next {
        right: 0;
        margin-left: 25px;
    }
    .carousel-control-prev {
        left: 0;
        margin-right: 25px;
    }

    .carousel-inner {
        overflow: ${props => props.home ? 'unset' : 'hidden'}
    }
    .carousel-control-next, .carousel-control-prev {
        position: relative;
        bottom: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        border: 3px solid #147efb;
        background-color: #147efb;
        color: #fff;
        text-align: center;
        margin-top: 40px;
        box-shadow: 0 1px 10px rgba(0,0,0,.3);
    }
    .carousel-item{
        display: none;
    }
    .active{
        display: block;
    }
`

export default StyledCarousel